.footer-history {
  padding-left: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 5px;
  &-wrapper {
    padding-bottom: 20px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 9px;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 600;
    line-height: 19.36px;
    text-align: left;
    color: #fff;
  }
}

// .xIcon {

//     transform: translateX(-5px); /* Pomeranje 10px u levo */

// }

// .footer-history-wrapper ul {
//   align-items: center; /* Vertikalno centriranje */
//   justify-content: center;
//   padding-top: 6px;
// }
