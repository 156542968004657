.c-reset-security {
  padding-top: pxToRem(56px);

  @include media-below($bp-md) {
    padding-top: pxToRemMd(40px);

    .c-reset-security__button {
      width: 100%;
      margin-top: pxToRemMd(44px);
    }
  }
}

.c-reset-security__question {
  color: $dark-blue;
  font-weight: 600;
  margin-bottom: pxToRem(20px);
}

.c-reset-security__button {
  width: 100%;
  margin-top: pxToRem(48px);
}
