button[data-setter] {
    outline: none;
    background: transparent;
    border: none;
    font-family: "Roboto";
    font-weight: 300;
    font-size: 18px;
    width: 25px;
    height: 30px;
    color: #fff;
    cursor: pointer;
}

button[data-setter]:hover {
    opacity: 0.5;
}

button {
    border: none;
    background: transparent;
    cursor: pointer;
}

.container {
    position: relative;
    width: fit-content;
    margin: 0 auto;
}

.setters {
    position: absolute;
    left: 85px;
    top: 75px;
}

.minutes-set {
    float: left;
    margin-right: 28px;
}

.seconds-set {
    float: right;
}

.circle {
    width: 100%;
    transform: scaleX(-1);
}

.controls {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.controls-paused {
    /* position: absolute; */
    margin-left: 8px;
    margin-bottom: 3px;
}

#pause {
    outline: none;
    background: transparent;
    border: none;
    width: 50px;
    height: 50px;
    position: relative;
}

.e-c-base {
    fill: none;
    stroke: transparent;
    stroke-width: 5px;
}

.e-c-progress {
    fill: none;
    /* stroke: #fff; */
    stroke: #fff;
    stroke-width: 15px;
    transition: stroke-dashoffset 0.7s;
    stroke-dasharray: calc(pi * 2 * 100);
}

/* .e-c-pointer {
    fill: #fff;
    stroke: #fff;
    stroke-width: 2px;
} */

#e-pointer {
    transition: transform 0.7s;
}

.play-controller {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* .MuiFormControl-root .MuiInputBase-root:nth-child(1) input,
.MuiFormControl-root .MuiInputBase-root:nth-child(1) input::placeholder {
    color: #000;
} */

/* 
.MuiFormControl-root .MuiInputBase-root:nth-child(1) {
    background: #B6DBA2;
} */