/**
 * Reset
 *
 */

*,
*:before,
*:after {
  box-sizing: border-box;
}

*,
body,
button,
input,
textarea,
select {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
button,
input,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset,
img {
  border: 0;
}

address,
caption,
cite,
code,
dfn,
em,
th,
var {
  font-style: normal;
  font-weight: normal;
}

strong {
  font-weight: 800;
}

ol,
ul {
  list-style: none;
}

caption,
th {
  text-align: left;
}

q:before,
q:after {
  content: '';
}

abbr,
acronym {
  border: 0;
}

svg {
  flex-shrink: 0;
}

textarea,
input:matches([type='email'], [type='number'], [type='password'], [type='search'], [type='tel'], [type='text'], [type='url']) {
  -webkit-appearance: none;

  &::-webkit-autofill,
  &::-webkit-contacts-auto-fill-button,
  &::-webkit-credentials-auto-fill-button {
    visibility: hidden;
    user-select: none;
    display: none !important;
    position: absolute;
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;

  &::-webkit-autofill,
  &::-webkit-contacts-auto-fill-button,
  &::-webkit-credentials-auto-fill-button {
    visibility: hidden;
    user-select: none;
    display: none !important;
    position: absolute;
  }
}
