body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
button,
input,
textarea,
p,
blockquote,
th,
td {
  font-family: $font-family;
}

p {
  vertical-align: middle;
  display: inline-block;
  word-break: break-word;
  font-size: pxToRem(16px);
  line-height: 1.5;

  @include media-below($bp-md) {
    font-size: pxToRemMd(16px);
  }
}

a {
  font-size: inherit;
  line-height: inherit;
  color: inherit;
}

strong {
  font-weight: bold;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}
