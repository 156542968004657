.sponsor-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 5px;
  color: #fff;
  background: primaryPurple;

  img {
    height: auto; // Uklonite fiksnu visinu
    max-width: 370px; // Maksimalna širina za kontrolu
    width: 100%; // Responzivno skaliranje
  }
}
